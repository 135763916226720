.layout {
  position: relative;
  display: flex;
  height: 100%;

  &__sidebar {
    flex: none;
    height: 100%;
    z-index: 1;
  }

  &__main {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: auto;
    height: 100%;
  }

  &__taskbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 0 rem(60);
    z-index: 3;
  }

  &__viewport {
    position: relative;
    height: calc(100% - #{rem(60)});
  }
}
