.button {
  @include type(label);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: rem(32);
  padding: 0 rem(20);
  color: cc(grey, darker);
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 rem(2) 0.5rem rgba(black, 0.1);
  backface-visibility: hidden;
  transition:
    background-color 175ms cb(ease),
    border-color 225ms ease-in-out,
    background-position 25095493ms ease-in-out;
  white-space: nowrap;

  &.-action {
    margin-top: rem(20);
  }

  &.-primary {
    color: cc(offset, light);
    background-color: cc(primary);
    border: none;

    &.-outlined {
      color: cc(primary);
      background-color: transparent;
      border: rem(1) solid cc(primary);
      box-shadow: none;

      &:hover {
        color: cc(primary, darker);
        background-color: transparent;
        transition: color 175ms cb(ease);
      }
    }

    &:hover {
      background-color: cc(primary, darker);
    }
  }

  &.-link {
    @include type(link);
    height: auto;
    padding: 0;
    color: cc(primary);
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
  }

  &.-disabled,
  &:disabled {
    opacity: $disabled-opacity;
    cursor: $cursor-not-allowed;
  }

  &.-delete {
    color: cc(offset, light);
    background-color: cc(state, negative);
    border: none;

    &:hover {
      background-color: cc(state, error);
    }
  }

  &.-add {
    color: cc(primary);
    background-color: cc(offset, lightest);
    box-shadow: none;

    &::before {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      width: rem(13);
      height: rem(13);
      margin-right: rem(5);
      padding-top: rem(2);
      color: white;
      background-color: cc(primary);
      border-radius: 100%;
      backface-visibility: hidden;
    }

    &:hover {
      background-color: cc(offset, lightest, 0.6);
    }
  }

  &.-load-more,
  &.-show-more {
    color: cc(primary);
    background: none;
    box-shadow: none;

    .icon {
      z-index: -1;
    }
  }

  &.-margin-left {
    margin-left: 1rem;
  }

  &.-dropdown {
    position: relative;
    padding-right: 1.75rem;
    padding-left: 1rem;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      right: 0.75rem;
      bottom: 50%;
      width: 0.4rem;
      height: 0.4rem;
      border-right: 1px solid currentColor;
      border-bottom: 1px solid currentColor;
      transform: translate3d(0, 25%, 0) scaleY(1) rotate(45deg);
      transition: transform 200ms cb();
    }

    &.-transparent {
      color: black;
      background: transparent;
      box-shadow: none;
      font-weight: 400;
    }

    &.-open {
      pointer-events: none;

      &::after {
        transform: translate3d(0, 50%, 0) scaleY(-1) rotate(45deg);
      }
    }
  }

  &:hover {
    background-color: rgba(white, 0.7);
  }

  &__icon {
    margin-right: rem(10);
    font-size: rem(12);

    &.-big {
      margin-right: rem(10);
      font-size: rem(16);
    }

    &.-right-icon {
      margin: 0 0 0 rem(4);
    }

    &.-disabled {
      color: cc(state);
    }

    &.-approved {
      color: cc(state, success);
    }

    &.-declined {
      color: cc(state, error);
    }
  }

  &__icon-circled {
    position: relative;
    width: rem(20);
    height: rem(20);
    color: cc(primary);
    background-color: white;
    border-radius: 50%;
    transition: background-color 125ms linear;
    font-size: rem(9);

    &:hover {
      background-color: cc(grey, lightest);
    }
  }

  &__icon-circled.-dark {
    background-color: cc(offset, light);

    &:hover {
      background-color: cc(offset, neutral);
    }
  }
}
