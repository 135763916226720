// stylelint-disable
.Toastify {
  &__toast {
    display: flex;
    align-items: center;
    min-height: rem(40);
    margin-right: rem(10);
    border-radius: rem(4);
    font-size: rem(13);
    line-height: normal;

    &::before {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: none;
      width: rem(16);
      height: rem(16);
      margin-right: rem(10);
      margin-left: rem(10);
      padding-top: rem(1);
      background-color: white;
      border-radius: 100%;
      backface-visibility: hidden;
      font-size: rem(12);
      font-weight: 600;
    }

    &--error {
      background-color: cc(state, error);

      &::before {
        display: none;
      }

      .toast-wrapper {
        display: flex;
        flex-direction: row;
      }

      .icon {
        align-self: center;
        margin-top: rem(-2);
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: rem(16);
      }
    }

    &--success {
      background-color: cc(state, success);

      &::before {
        content: '\2713';
        padding-bottom: rem(2);
        color: cc(state, success);
      }
    }

    &--warning {
      background-color: cc(primary);

      &::before {
        content: 'i';
        color: cc(primary);
      }
    }
  }

  &__close-button {
    @include type(input);
    align-self: center;
    opacity: 1;
    font-weight: 400;
  }
}
// stylelint-enable
