.view {
  overflow-x: hidden;
  width: 100%;
  min-width: rem(1050);
  min-height: calc(100vh - 3.75rem);
  padding: rem(30) rem(50) rem(160);

  &__taskbar {
    display: flex;
    align-items: center;

    &.-inline {
      margin-left: rem(20);
    }

    &.-heading,
    & + .tabs {
      margin-top: rem(20);
    }

    &.-heading {
      justify-content: space-between;
      padding-right: rem(8);
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin-top: rem(30);
  }

  &__action .icon {
    padding-right: rem(5);
  }

  &__title {
    @include type(title);
    margin-right: rem(20);
    color: cc(primary);
    white-space: nowrap;

    &.-dark {
      color: cc(grey, darker);
    }

    &.-error {
      color: cc(state, negative);
    }
  }

  &__subtitle {
    @include type(heading-small);
    margin-right: rem(20);
    color: cc(primary);
    white-space: nowrap;
  }

  &__title + &__subtitle {
    margin-left: rem(-10);
  }

  &__heading {
    @include type(heading);
    color: black;
    white-space: nowrap;

    &::before {
      content: '';
      display: block;
      visibility: hidden;
      width: 100%;
      height: rem(30);
    }

    &.-taskbar {
      display: flex;
      align-items: center;
      margin-top: rem(40);

      &::before {
        display: none;
      }
    }

    &.-padded {
      margin-top: rem(20);
    }

    &.-error {
      color: cc(state, negative);
    }
  }

  &__header + &__heading::before {
    display: none;
  }

  &__legend {
    @include type(legend);
    text-transform: uppercase;

    &.-label {
      color: cc(grey, darker);
    }

    &.-primary {
      color: cc(primary, neutral);
    }
  }

  &__content {
    padding: rem(20) 0;
  }

  &__section {
    margin-bottom: rem(40);
  }

  &__info {
    @include type(info);
  }

  &__meta,
  &__action {
    & + & {
      margin-left: rem(10);
    }

    &.-link {
      margin-right: rem(20);
    }

    &.-heading {
      margin-left: rem(-15);
      padding-left: 0;
    }
  }

  &__title + &__meta,
  &__subtitle + &__meta {
    margin-top: -0.25rem;
  }
}
