.tabs {
  padding-left: rem(10);
  border-bottom: rem(1) solid cc(grey, light);

  &__tab {
    margin-bottom: rem(-1);
    padding: rem(10);

    &.-active {
      background-color: white;
      border: solid cc(grey, light);
      border-width: rem(1) rem(1) 0;
    }

    &.-form {
      border: none;
    }
  }

  &__form {
    padding-top: rem(20);
    border: none;
  }
}
