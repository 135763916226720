.menu {
  flex-shrink: 0;
  width: rem(240);
  background-color: cc(menu, background);
  user-select: none;

  .active {
    color: cc(primary, default);
    font-weight: 700;
  }

  &__section {
    border-bottom: rem(1) solid cc(grey, dark);
  }

  &__content {
    @include type(link);
    padding: rem(25);
    color: cc(grey, default);
  }

  &__title {
    @include type(title-small);
    display: inline-block;
    width: 100%;
    margin: rem(25) 0 rem(10) 0;
    padding: rem(25);
    color: cc(grey, default);
    text-align: left;

    &:hover {
      color: cc(primary, bland);
    }
  }

  &__version {
    display: block;
    width: 100%;
    color: cc(grey, default);
    text-align: center;
  }

  &__link {
    display: block;
    height: rem(20);
    cursor: pointer;

    & + & {
      margin-top: rem(20);
    }

    &:hover {
      color: cc(primary, bland);
    }
  }
}
