.row {
  display: flex;
  align-items: center;
  margin-bottom: rem(20);

  &.-flat {
    margin-bottom: 0;
  }

  &.-align-bottom {
    align-items: flex-end;
  }

  &.-align-right {
    justify-content: flex-end;
  }

  &.-justify {
    justify-content: space-between;
  }

  &__item {
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;

    & + & {
      margin-left: rem(20);
    }

    &.-grow {
      flex-grow: 1;
    }
  }
}
