.grid {
  display: grid;
  grid-template-columns: [left] repeat(6, 1fr) [right] repeat(6, 1fr);
  grid-gap: rem(20) rem(30);

  &.-indent {
    padding-left: rem(20);
  }

  &.-indent-top {
    margin-top: rem(30);
  }

  &.-padded {
    grid-row-gap: rem(25);
    padding: rem(10) rem(5) rem(50) rem(5);
  }

  &.-tabs {
    overflow: hidden;
    width: 100%;
    padding: 0 rem(15);
  }

  &.-small {
    max-width: rem(375);
  }

  &.-last {
    border-bottom: solid rem(1) cc(grey, light);
  }

  &__action {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  &__heading {
    @include type(label);
    color: cc(offset, dark);
    white-space: nowrap;
    &.-bug-rate-heading-small {
      @include type(heading-small);
    }
    &.-bug-rate-heading {
      @include type(heading);
    }
    &.-project-section {
      @include type(heading);
      padding: rem(20) 0;
    }
  }

  &__row {
    @extend .grid;
    position: relative;
    grid-column: left / span 12;

    @for $i from 1 through 12 {
      &.-indent-#{$i} {
        padding-left: rem($i * 20);
      }
    }

    &.-header {
      margin-top: 1rem;
    }

    &.-odd {
      background-color: cc(primary, much-lighter);
    }

    &.-bug-rate-list-row {
      padding: rem(15) 0;
    }

    &.-indent {
      padding-left: rem(20);
    }

    &.-conditions-exclusions {
      grid-row-gap: rem(10);
    }

    &.-disabled span:not(.-checkbox) {
      opacity: $disabled-opacity;
      cursor: $cursor-not-allowed;

      .-checkbox {
        pointer-events: auto;
      }

      .view-only & {
        opacity: 1;
        cursor: inherit;
      }
    }

    &.-hover,
    &.-linked {
      align-content: center;
      min-height: rem(30);

      &::before {
        content: '';
        position: absolute;
        top: rem(-5);
        right: rem(-10);
        bottom: rem(-5);
        left: rem(-10);
        padding: 0.5rem 0.75rem;
        background-color: cc(offset, lightest, 0);
        border-radius: rem(4);
        transition: background-color 125ms linear;
      }

      &:hover::before {
        background-color: cc(offset, lightest);
      }
    }

    &.-highlightable {
      align-content: center;
      min-height: rem(30);

      &::before {
        content: '';
        position: absolute;
        top: rem(-5);
        right: rem(-10);
        bottom: rem(-5);
        left: rem(-10);
        padding: 0.5rem 0.75rem;
        border-radius: rem(4);
      }

      &:hover::before {
        background-color: cc(primary, lighter);
      }
    }

    &.-linked {
      cursor: pointer;
    }

    &.-custom {
      padding-bottom: rem(5);
    }
  }

  &__tooltip {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 0;

    &.-disabled {
      opacity: $disabled-opacity;
      cursor: $cursor-not-allowed;
    }

    &.-border {
      padding-bottom: 0.25rem;
      border-bottom: 1px solid cc(grey, light);
      transition: border-color 125ms linear;

      .view-only & {
        border-bottom: none;
      }

      .grid__item:hover & {
        border-color: cc(grey, lighter);
      }
    }

    .-checkbox & {
      padding-left: 0.5rem;
    }

    > .icon:not(.grid__icon) {
      cursor: auto;
      font-size: 1rem;
    }
  }

  &__icon {
    color: cc(grey);
    cursor: pointer;
    font-size: rem(14);

    &.-inline {
      margin-right: 0.5rem;
    }

    &.-disabled {
      opacity: $disabled-opacity;
      cursor: $cursor-not-allowed;
    }

    &.-hover {
      opacity: 0;
      transform: translate3d(-0.25rem, 0, 0);
      transition: transform 200ms cb(), opacity 175ms linear;
      transition-delay: 100ms;
    }

    &.-append {
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate3d(-0.25rem, -55%, 0);

      &.-top {
        top: 25%;
      }

      &.-exclusion {
        top: 55%;
        right: 0;
      }
    }

    &.-default-cursor {
      cursor: default;
    }

    &.-incomplete {
      color: cc(state, error);
    }

    &.-fit {
      margin-top: rem(-7);
    }
  }

  &__row:hover &__icon.-hover {
    opacity: 1;
    transform: translateZ(0);
    transition-delay: 0ms;

    &.-append {
      transform: translate3d(0, -55%, 0);
    }
  }

  &__row.-linked &__icon.-hover.-append {
    transform: translate3d(-1rem, -50%, 0);
  }

  &__row.-linked:hover &__icon.-hover.-append {
    transform: translate3d(-0.75rem, -50%, 0);
  }

  &__group {
    right: rem(-15);
    left: rem(-15);
    align-self: flex-start;
    width: calc(100% + #{rem(30)});
  }

  &__group-line {
    top: rem(-10);
    right: rem(-15);
    left: rem(-15);
    width: calc(100% + #{rem(30)});
    height: 1px;
    background-color: cc(offset, light);
  }

  &__group-heading {
    @include type(super);
    padding: rem(10) rem(5);
    color: cc(primary, darkest, 0.5);
    background-color: cc(offset, light);
    text-align: center;
    text-transform: uppercase;
  }

  &__logo {
    &.-small {
      display: inline-block;
      width: 1.5rem;
      margin-right: 1rem;
    }
  }

  &__row,
  &__heading,
  &__item {
    @for $i from 2 through 12 {
      &.-span-#{$i} {
        grid-column-end: span $i;
      }
    }
  }

  &__heading,
  &__item {
    position: relative;
    grid-column: auto / auto;
    align-self: end;

    &.-bordered-left::before,
    &.-bordered-right::after {
      content: '';
      position: absolute;
      bottom: rem(-10);
      width: 1px;
      height: calc(100% + #{rem(35)});
      background: cc(offset, light);
    }

    &.-bordered-left::before {
      left: rem(-15);
    }

    &.-bordered-right::after {
      right: rem(-15);
    }

    &.-left-half {
      grid-column: left / span 6;
    }

    &.-right-half {
      grid-column: right / span 5;
    }

    &.-start-row {
      grid-column-start: left;
    }

    &.-box-top {
      align-self: start;
    }

    &.-box-middle {
      align-self: center;
    }

    &.-indent {
      margin-left: rem(20);
    }

    &.-padded {
      padding-bottom: rem(4);
    }

    &.-medium {
      min-width: rem(83);
    }

    &.-box-center {
      align-items: center;
      height: 100%;
    }

    &.-box-center,
    &.-align-center {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .-align-right &,
    &.-align-right {
      text-align: right;

      .view-only & {
        text-align: left;
      }
    }
  }
}
