.radio-button {
  &__indicator {
    @extend .button;
    @include type(link);
    height: 2.75rem;
    padding: 0 rem(24);
    color: cc(offset, medium);
    border: 1px solid cc(offset, medium);
    border-radius: 2px;
    box-shadow: none;
    cursor: pointer;
    transition: color 175ms cb(ease);

    &:hover {
      color: cc(offset, darkest);
      border-color: cc(offset, darkest);
    }
  }

  &__field.-disabled + &__indicator {
    opacity: $disabled-opacity;
    cursor: $cursor-not-allowed;

    &:hover {
      color: cc(offset, medium);
      border: 1px solid cc(offset, medium);
    }

    > .icon {
      cursor: $cursor-not-allowed;
    }
  }

  &__field.-disabled:checked + &__indicator {
    color: cc(primary);
    border: 2px solid cc(primary);
  }

  &__field:not(.-disabled):checked + &__indicator {
    color: cc(primary);
    border: 2px solid cc(primary);
  }

  &__label {
    @extend .input__label;

    .-modal > & {
      margin-bottom: rem(15);
    }
  }

  &__icon {
    margin-right: rem(8);
    font-size: rem(16);

    &.-big {
      font-size: rem(20);
    }
  }
}
