.DateRangePicker {
  width: 100%;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
}

.DateInput {
  flex: 3;
}

.DateRangePickerInput_arrow {
  flex: 1;
  text-align: center;
}

.DateInput_input {
  // Will edit the selected input
  &__focused {
    border-bottom: 2px solid cc(primary, 'default') !important;
  }
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: cc(primary, 'lighter') !important; //background
  color: white; //text
  border: 1px solid cc(primary, 'default') !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: cc(primary, 'default') !important;
  color: white;
  border: 1px solid cc(primary, 'default') !important; //default styles include a border
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: cc(primary, 'lighter');
}
