.tile {
  position: relative;
  margin-top: rem(20);
  padding: rem(25) rem(20);
  background-color: white;
  border-radius: rem(4);

  &.-no-top {
    margin-top: 0;
  }

  &.-scroll {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rem(40);
      background-image: linear-gradient(to bottom, rgba(white, 0), rgba(white, 1) 75%);
      pointer-events: none;
    }
  }

  &.-client {
    height: rem(320);
    padding-right: rem(10);

    & .simplebar-scrollbar {
      width: rem(2);
    }
  }

  &.-small {
    max-width: rem(675);
    margin-top: rem(40);

    & > * {
      padding: rem(15);
    }
  }

  &.-scroll &__content {
    padding-bottom: rem(40);
  }

  .view-only & {
    background-color: cc(offset, lighter);
  }

  &__form {
    margin-bottom: rem(10);

    & + &,
    &.-narrow {
      margin-top: 0;
    }
  }

  &__scrollbox {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(30);
  }

  &__row {
    display: flex;
    margin-top: rem(30);
  }

  &__group + &__group {
    margin-left: rem(20);
  }

  &__card {
    @include type(link);
    position: relative;
    width: calc(100% - #{rem(15)});
    padding: rem(10);
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 125ms linear;

    &.-push {
      margin-bottom: rem(20);
    }

    .icon {
      position: absolute;
      top: rem(12);
      right: rem(10);
      color: cc(offset, darker);
      opacity: 0;
      transform: translate3d(-0.25rem, 0, 0);
      transition: transform 200ms cb(), opacity 175ms linear;
    }

    &:hover {
      background-color: cc(offset, lightest);

      .icon {
        opacity: 1;
        transform: translateZ(0);
      }
    }
  }

  &__heading {
    @include type(legend);
    color: cc(grey, darker);
  }

  &__title {
    @include type(heading, light);
  }

  &__dots {
    position: absolute;
    top: rem(18);
    right: rem(20);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: rem(18);
    height: rem(8);

    &::before,
    &::after {
      content: '';
    }

    &::before,
    &::after,
    .-dot {
      display: block;
      width: rem(4);
      height: rem(4);
      background-color: cc(grey, lighter);
      border-radius: 100%;
    }
  }

  &__dots + &__row {
    margin-top: 0;
  }

  &__action {
    position: absolute;
    top: rem(18);
    right: rem(20);
  }
}
