// type definitions
%type-default {
  font-family: ProximaNova, sans-serif;
  font-size: rem(14);
  font-weight: 400;
}

%type-title {
  font-size: rem(28);
  font-weight: 400;
}

%type-title-small {
  font-size: rem(21);
  font-weight: 400;
}

%type-heading {
  font-size: rem(18);
  font-weight: 600;
}

%type-heading-small {
  font-size: rem(16);
  font-weight: 600;
}

%type-heading-light {
  font-size: rem(16);
  font-weight: 400;
}

%type-list-icon {
  font-size: rem(12);
}

%type-legend {
  font-size: rem(12);
  font-weight: 700;
  letter-spacing: rem(1);
}

%type-legend-light {
  font-size: rem(12);
  letter-spacing: rem(1);
}

%type-legend-small {
  font-size: rem(10);
  font-weight: 600;
}

%type-label {
  font-size: rem(13);
  font-weight: 600;
}

%type-label-small {
  font-size: rem(13);
  font-weight: 400;
}

%type-placeholder {
  font-size: rem(13);
  font-style: italic;
  font-weight: 400;
}

%type-placeholder-title {
  font-size: rem(28);
  font-style: italic;
  font-weight: 400;
}

%type-link {
  font-size: rem(14);
  font-weight: 400;
}

%type-input {
  @extend %type-link;
  letter-spacing: 0.015em;
}

%type-total {
  font-size: rem(12);
  font-weight: 700;
}

%type-total-big {
  font-size: rem(21);
  font-weight: 400;
}

%type-caption {
  font-size: rem(12);
  font-style: italic;
  font-weight: 600;
}

%type-caption-small {
  font-size: rem(10);
  font-weight: 600;
}

%type-error {
  font-size: rem(12);
  font-style: italic;
  font-weight: 400;
}

%type-super {
  font-size: rem(10);
  font-weight: 700;
}

%type-info {
  font-size: rem(14);
  font-style: italic;
  font-weight: 600;
}
